import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/components/layout/index.jsx";
import { MathJax } from 'better-react-mathjax';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Geotechnical Investigation`}</h1>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/wp-content/uploads/2020/05/GI-Lec1-Introduction-to-soil-and-rocks.pdf"
      }}>{`GI Lec#1 Introduction to soil and rocks`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/wp-content/uploads/2020/05/GI-Lec2-Subsurface-exploration.pdf"
      }}>{`GI Lec#2 Subsurface exploration`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/wp-content/uploads/2020/05/GI-Lec3-Methods-of-exploration.pdf"
      }}>{`GI Lec#3 Methods of exploration`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/wp-content/uploads/2020/05/GI-Lec4-Site-investigation.pdf"
      }}>{`GI Lec#4 Site investigation`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/wp-content/uploads/2020/05/GI-Lec5-Permeability-of-alluvium.pdf"
      }}>{`GI Lec#5 Permeability of alluvium`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/wp-content/uploads/2020/05/Ground-exploration-by-BG-Clarke.pdf"
      }}>{`Ground exploration by BG Clarke`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/wp-content/uploads/2020/05/Mechanical-and-electromagnetic-wave-measurement-techniques.pdf"
      }}>{`Mechanical and electromagnetic wave measurement techniques`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/wp-content/uploads/2020/05/Standard-penetration-test-Manual.pdf"
      }}>{`Standard penetration test Manual`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/wp-content/uploads/2020/05/Bearing-Capacity-of-shallow-foundations.pdf"
      }}>{`Bearing Capacity of shallow foundations`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/wp-content/uploads/2020/05/Evaluating-Liquefaction-Potential-based-on-SPT-N.pdf"
      }}>{`Evaluating Liquefaction Potential based on SPT-N`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/wp-content/uploads/2020/05/Settlement-analysis.pdf"
      }}>{`Settlement analysis`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/wp-content/uploads/2020/05/Specimen-Report.pdf"
      }}>{`Specimen Report`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/wp-content/uploads/2020/05/Geotechnical-engineering-laboratory-testing-manual.pdf"
      }}>{`Geotechnical engineering laboratory testing manual`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      